import {ChangeEvent, FC, useCallback, useEffect} from 'react'
import {addPhonePrefix, needAddPrefix, trimPhoneBasic} from 'src/app/utils/input-utils'
import {FormText, FormTextProps} from '../partials'

interface FormPhoneProps extends FormTextProps {
  onChangeValue: (value: string, first?: boolean) => void
  value: string
}

const FormPhone: FC<FormPhoneProps> = ({value, onChangeValue, ...rest}) => {
  const setValue = useCallback(
    (trimmed: string, first?: boolean) => {
      if (onChangeValue) onChangeValue(trimmed, first)
    },
    [onChangeValue]
  )

  const onChangeWrapper = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(addPhonePrefix(e.currentTarget.value))
    },
    [setValue]
  )

  useEffect(() => {
    if (needAddPrefix(value)) setValue(trimPhoneBasic(value), true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (value === '62') {
      setValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FormText
      value={value ? trimPhoneBasic(value) : ''}
      onChange={onChangeWrapper}
      prefix='+62'
      maxLength={15}
      {...rest}
    />
  )
}

export {FormPhone}
