import axios from 'axios'
import {BaseResponse, ICompany, ICompanyQuery, Pagination, Response} from 'src/app/models'

export const COMPANY = '/api/v1/admins/companies'

//react-query key
export const LIST_COMPANY_KEY = 'list-companies'
export const DETAIL_COMPANY_KEY = 'detail-companies'

export const getListCompany = (params?: ICompanyQuery) => {
  return axios.get<BaseResponse<Pagination<ICompany[]>>>(COMPANY, {
    params,
  })
}

export const getDetailCompany = (id?: string) => {
  return axios.get<BaseResponse<Response<ICompany>>>(`${COMPANY}/${id}`)
}

export const saveCompany = (data?: any, id?: string) => {
  if (id) return axios.put(`${COMPANY}/${id}`, data)
  return axios.post(COMPANY, data)
}

export const deleteCompany = (id?: string) => {
  return axios.delete(`${COMPANY}/${id}`)
}

// react-select-api
export const selectListCompany = async (search: string, loadedOptions: any) => {
  try {
    const payload = await getListCompany({
      page: Math.ceil(loadedOptions.length / 10),
      size: 10,
      search: search,
    })

    const data = payload.data.response_output?.list?.content ?? []

    const options = data?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))

    return {
      options,
      hasMore:
        options.length + loadedOptions.length <
        (payload.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}
