import axios from 'axios'
import {BaseResponse, IDepartment, IDepartmentQuery, Pagination, Response} from 'src/app/models'

export const DEPARTMENT = '/api/v1/admins/departments'

//react-query key
export const LIST_DEPARTMENT_KEY = 'list-departments'
export const DETAIL_DEPARTMENT_KEY = 'detail-departments'

export const getListDepartment = (params?: IDepartmentQuery) => {
  return axios.get<BaseResponse<Pagination<IDepartment[]>>>(DEPARTMENT, {
    params,
  })
}

export const getDetailDepartment = (id?: string) => {
  return axios.get<BaseResponse<Response<IDepartment>>>(`${DEPARTMENT}/${id}`)
}

export const saveDepartment = (data?: any, id?: string) => {
  if (id) return axios.put(`${DEPARTMENT}/${id}`, data)
  return axios.post(DEPARTMENT, data)
}

export const deleteDepartment = (id?: string) => {
  return axios.delete(`${DEPARTMENT}/${id}`)
}

export const getListDepartmentCombo = (params?: IDepartmentQuery) => {
  return axios.get<BaseResponse<Pagination<IDepartment[]>>>(`${DEPARTMENT}/combo`, {
    params,
  })
}

// react-select-api
export const selectListDepartment = async (search: string, loadedOptions: any) => {
  try {
    const payload = await getListDepartment({
      page: Math.ceil(loadedOptions.length / 10),
      size: 10,
      search: search,
    })

    const data = payload.data.response_output?.list?.content ?? []

    const options = data?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))

    return {
      options,
      hasMore:
        options.length + loadedOptions.length <
        (payload.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}

export const selectListDepartmentCombo = async (search: string, loadedOptions: any) => {
  try {
    const payload = await getListDepartmentCombo({
      page: Math.ceil(loadedOptions.length / 10),
      size: 10,
      search: search,
    })

    const data = payload.data.response_output?.list?.content ?? []

    const options = data?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))

    return {
      options,
      hasMore:
        options.length + loadedOptions.length <
        (payload.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}
