import axios from 'axios'
import {BaseResponse, ICategory, ICategoryQuery, Pagination, Response} from 'src/app/models'

export const CATEGORY = '/api/v1/admins/services/categories'

//react-query key
export const LIST_CATEGORY_KEY = 'list-category'
export const DETAIL_CATEGORY_KEY = 'detail-category'
export const ALLOW_UPDATE_CATEGORY_KEY = 'allow-update-category'

export const getListCategory = (params?: ICategoryQuery) => {
  return axios.get<BaseResponse<Pagination<ICategory[]>>>(CATEGORY, {
    params,
  })
}

export const getDetailCategory = (id?: string) => {
  return axios.get<BaseResponse<Response<ICategory>>>(`${CATEGORY}/${id}`)
}

export const saveCategory = (data?: any, id?: string) => {
  if (id) return axios.put(`${CATEGORY}/${id}`, data)
  return axios.post(CATEGORY, data)
}

export const deleteCategory = (id?: string) => {
  return axios.delete(`${CATEGORY}/${id}`)
}

export const allowUpdateDeleteCategory = (id?: string) => {
  return axios.get<BaseResponse<Response<{is_can_update_delete: boolean}>>>(
    `${CATEGORY}/${id}/update-delete-validation`
  )
}
