const ProfileScreens = {
  PROFILE: {
    PATH: '/profile',
    TITLE: 'Profile',
    TITLE_ALT: 'Profile',
  },
  CHANGE_PROFILE: {
    PATH: '/profile/user',
    TITLE: 'Profile',
    TITLE_ALT: 'Profile',
  },
  CHANGE_PASSWORD: {
    PATH: '/profile/change-password',
    TITLE: 'Change Password',
    TITLE_ALT: 'Change Password',
  },
}

export default ProfileScreens
