import {FormikProps} from 'formik'
import {useCallback} from 'react'
import {useHeaderToast} from '../context/ToastContext'
import {getErrorMessage, getErrors} from '../utils/api-utils'
import {useOnline} from './online-hook'

export interface IErrorQuery {
  errorTemp: (e: unknown) => void
  errorComplete: (e: unknown, formik?: FormikProps<unknown>) => void
}

export function useErrorQuery() {
  const {addPageToasts} = useHeaderToast()
  const {isOnline, errorOffline} = useOnline()

  const isAxiosError = (error: unknown): error is {response: {status: number}} =>
    typeof error === 'object' && error !== null && 'response' in error

  const errorOnlineToast = useCallback(
    (e: unknown) => {
      if (isAxiosError(e) && e.response.status >= 400 && e.response.status < 500) {
        if (e.response.status === 404) {
          addPageToasts({
            scheme: 'danger',
            text: 'Client Error: Please check your request and try again.',
          })
        } else {
          addPageToasts({scheme: 'danger', text: getErrorMessage(e, true)})
        }
      }

      if (isAxiosError(e) && e.response.status >= 500) {
        addPageToasts({scheme: 'danger', text: 'Something went wrong, please try again later.'})
        console.error(e)
      }
    },
    [addPageToasts]
  )

  const errorTemp = useCallback(
    (e: unknown) => {
      if (isOnline) {
        errorOnlineToast(e)
      } else {
        addPageToasts({scheme: 'danger', text: errorOffline})
      }
    },
    [addPageToasts, errorOffline, isOnline, errorOnlineToast]
  )

  const errorComplete = useCallback(
    (e: unknown, formik?: FormikProps<unknown>) => {
      if (isOnline) {
        errorOnlineToast(e)

        const detailError = getErrors(e).detailed

        Object.entries(detailError).forEach(([key, value]) => {
          const removedValue = [
            'created_at',
            'updated_at',
            'deleted_at',
            'created_by',
            'updated_by',
            'deleted_by',
            'created_by_id',
            'updated_by_id',
            'deleted_by_id',
          ]
          if (removedValue.includes(key)) return

          if (['date'].includes(key)) {
            formik?.setFieldError('start_date', value)
            formik?.setFieldError('end_date', value)
          }

          formik?.setFieldError(`${key}`, value)
        })
      } else addPageToasts({scheme: 'danger', text: errorOffline})
    },
    [addPageToasts, errorOffline, isOnline, errorOnlineToast]
  )

  return {errorTemp, errorComplete}
}
