import {useMutation} from '@tanstack/react-query'
import {FormikProps, useFormik} from 'formik'
import {FC, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {Bounce, toast} from 'react-toastify'
import {FormLabel, FormPassword, FormText, GButton} from 'src/app/components/Libs'
import {FormPhone} from 'src/app/components/Libs/Form/Custom'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {useErrorQuery} from 'src/app/hooks/error-query-hook'
import {useOnline} from 'src/app/hooks/online-hook'
import {IOnboarding} from 'src/app/models/Onboarding/Onboarding.models'
import {saveOnboarding} from 'src/app/services'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {emailRegExp, phoneRegExp, phoneStartsWith62And8RegExp} from 'src/app/utils/input-utils'
import * as Yup from 'yup'
import CustomToast from '../CustomToast/CustomToast'
import TopLayout from '../Layout/TopLayout'
import {PasswordFormUnique} from './PasswordFormUnique'

interface IProps extends IOnboarding {
  token: string
}

const IdentitySection: FC<IProps> = ({state, token}) => {
  const dispatch = useDispatch()
  const {errorComplete} = useErrorQuery()
  const {isOnline} = useOnline()

  const submitFn = useMutation({
    mutationFn: saveOnboarding,
    onSuccess: () => {
      dispatch(OnboardingRedux.actions.setStep(6))
    },
    onError: (e) => {
      if (isOnline) errorComplete(e, formik as FormikProps<any>)
      else
        toast(<CustomToast />, {
          position: 'bottom-left',
          autoClose: false,
          hideProgressBar: true,
          closeButton: false,
          transition: Bounce,
          className: 'w-[520px] p-0 shadow-none',
          bodyClassName: 'p-0 bg-transparent',
        })
    },
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...state.admin_data,
      email: state?.profile?.email,
    } as OnboardingRedux.TAdminDataState,
    initialErrors: state?.errors?.admin_data,
    initialTouched: {
      mobile_number: true,
      name: true,
      password: true,
      password_confirm: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Nama lengkap wajib diisi')
        .min(3, 'Minimal karakter 3')
        .max(255, 'Maksimal karakter 255'),
      email: Yup.string()
        .email('Format alamat email tidak valid')
        .required('Alamat email perusahaan wajib diisi')
        .matches(emailRegExp, 'Format alamat email tidak valid')
        .max(100, 'Maksimal karakter 100'),
      mobile_number: Yup.string()
        .trim()
        .matches(phoneRegExp, 'Nomor telepon harus terdiri dari 10 - 13 digit')
        .matches(phoneStartsWith62And8RegExp, 'Digit pertama harus angka 8')
        .required('Nomor telepon wajib diisi')
        .max(14, 'Nomor telepon harus terdiri dari 10 - 13 digit'),
      password: Yup.string()
        .matches(
          /^(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*\d).{8,}$/,
          'Pastikan password sesuai ketentuan'
        )
        .required('Password baru wajib diisi'),
      password_confirm: Yup.string()
        .test('equal', 'Password tidak sesuai', function (v) {
          // Don't use arrow functions
          const ref = Yup.ref('password')
          return v === this.resolve(ref)
        })
        .required('Ulangi password baru wajib diisi'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (state?.profile?.level !== 'L3') {
        dispatch(OnboardingRedux.actions.setAdminData(values))
        dispatch(OnboardingRedux.actions.setStep(3))
      } else {
        const payload = {
          activation_code: token,
          admin_data: {
            email: values.email,
            mobile_number: `+${values.mobile_number}`,
            name: values.name,
            password: values.password,
          },
          users: [],
          agents: [],
          task: null,
        }

        submitFn.mutate(payload)
      }
    },
  })

  const wordingAdmin = useMemo(() => {
    let word
    let endWord =
      'Mohon pastikan nama lengkap, email dan nomor telepon sudah sesuai seperti saat pendaftaran awal.'

    if (state?.profile?.level === 'L1') {
      word =
        'Anda adalah admin Level 1, yang memiliki kapabilitas membuat admin Level 1, Level 2, dan Level 3.'

      return `${word} ${endWord}`
    }

    if (state?.profile?.level === 'L2') {
      word =
        'Anda adalah admin Level 2, yang memiliki kapabilitas membuat admin Level 2 dan Level 3.'

      return `${word} ${endWord}`
    }

    if (state?.profile?.level === 'L3') {
      return `${endWord}`
    }
  }, [state?.profile?.level])

  return (
    <TopLayout state={state}>
      <form className='w-full bg-white rounded shadow' noValidate onSubmit={formik.handleSubmit}>
        <div className='px-10 pt-10 pb-6'>
          <div className='font-semibold text-neutral-900 text-fs-6'>Konfirmasi Identitas Anda</div>
          <div className='mt-2 text-neutral-600'>{wordingAdmin}</div>
        </div>

        <div className='px-10 pt-6 pb-8'>
          <div className='flex justify-between gap-14'>
            <div className='flex-1'>
              <div className='flex items-center gap-4'>
                <GIcon icon='IconUser2'></GIcon>
                <div className='font-medium text-fs-7 text-neutral-900'>Pengguna</div>
              </div>
              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Nama lengkap
                </FormLabel>
                <FormText
                  {...formik.getFieldProps('name')}
                  name='name'
                  placeholder='Nama lengkap saat pendaftaran awal'
                  maxLength={255}
                  inputClassName='bg-white'
                  error={formik.errors.name}
                  touched={formik.touched.name}
                />
              </div>
              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Nomor telepon
                </FormLabel>
                <FormPhone
                  onChangeValue={(value) => {
                    formik.setFieldValue('mobile_number', value)
                  }}
                  value={formik.values.mobile_number as string}
                  placeholder='Contoh: 81234567000'
                  error={formik.errors.mobile_number}
                  touched={formik.touched.mobile_number}
                  inputClassName='bg-white'
                />
                <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
                  <div>
                    <GIcon icon='IconInfoOutline' />
                  </div>
                  Nomor telepon secara default menjadi nomor bantuan untuk agen yang muncul pada
                  aplikasi agen. Apabila Anda ingin mengubahnya dapat dengan mengundang admin lain
                  dan menjadikan orang tersebut kontak untuk dihubungi agen lapangan.
                </div>
              </div>
            </div>

            <div className='flex-1'>
              <div className='flex items-center gap-4'>
                <GIcon icon='IconKey'></GIcon>
                <div className='font-medium text-fs-7 text-neutral-900'>Akun</div>
              </div>

              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Alamat email perusahaan
                </FormLabel>
                <FormText
                  {...formik.getFieldProps('email')}
                  name='email'
                  placeholder='Masukkan alamat email perusahaan'
                  maxLength={255}
                  inputClassName='bg-white disabled:bg-neutral-100'
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  disabled
                />
              </div>
              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Password baru
                </FormLabel>
                <PasswordFormUnique
                  {...formik.getFieldProps('password')}
                  name='password'
                  inputClassName='bg-white'
                  placeholder='Masukkan password baru'
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />
              </div>

              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Ulangi password baru
                </FormLabel>
                <FormPassword
                  {...formik.getFieldProps('password_confirm')}
                  name='password_confirm'
                  placeholder='Masukkan ulangi password baru'
                  inputClassName='bg-white'
                  error={formik.errors.password_confirm}
                  touched={formik.touched.password_confirm}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='w-full border-t border-neutral-200'></div>

        <div className='flex items-center justify-center py-5'>
          <GButton
            onClick={() => dispatch(OnboardingRedux.actions.setErrors({admin_data: {}}))}
            type='submit'
            className='w-[320px] h-[48px] bg-[#0085E5] border-[#0085E5]'
          >
            Konfirmasi
          </GButton>
        </div>
      </form>
    </TopLayout>
  )
}

export default IdentitySection
