import axios from 'axios'
import {BaseResponse, IUser, IUserQuery, Pagination, Response} from 'src/app/models'

export const USER = '/api/v1/admins/users'

//react-query key
export const LIST_USER_KEY = 'list-users'
export const DETAIL_USER_KEY = 'detail-users'

export const getListUser = (params?: IUserQuery) => {
  return axios.get<BaseResponse<Pagination<IUser[]>>>(USER, {
    params,
  })
}

export const getListUserByDepartment = (params?: IUserQuery, id?: string) => {
  return axios.get<BaseResponse<Pagination<IUser[]>>>(`${USER}/department/${id}`, {params})
}

export const getDetailUser = (id?: string) => {
  return axios.get<BaseResponse<Response<IUser>>>(`${USER}/${id}`)
}

export const saveUser = (data?: any, id?: string) => {
  if (id) return axios.put(`${USER}/${id}`, data)
  return axios.post(USER, data)
}

export const deleteUser = (id?: string) => {
  return axios.delete(`${USER}/${id}`)
}

export const fetchCustomer = async (search: string, loadedOptions: any, id?: string) => {
  try {
    const value = await getListUserByDepartment(
      {
        page: Math.ceil(loadedOptions.length / 10),
        size: 10,
        search,
      },
      id
    )
    const data = value.data.response_output?.list?.content ?? []
    const options = data?.map((item) => ({
      label: `${item.first_name} ${item.last_name}`,
      value: item.id,
      all: item,
    }))

    return {
      options,
      hasMore:
        options.length + loadedOptions.length <
        (value.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}
