import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const RoleScreen: Record<string, ScreenProp> = {
  ROLE: {
    PATH: '/administration/setup-role',
    TITLE: 'Role List',
    TITLE_ALT: 'Setup Role',
    PERMISSION: 'user_role.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ROLE_ADD: {
    PATH: '/administration/setup-role/add',
    TITLE: 'Add Role',
    TITLE_ALT: 'Add Role',
    PERMISSION: 'user_role.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  ROLE_EDIT: {
    PATH: '/administration/setup-role/edit/:id',
    TITLE: 'Edit Role ',
    TITLE_ALT: 'Edit Role',
    PERMISSION: 'user_role.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  ROLE_DETAIL: {
    PATH: '/administration/setup-role/detail/:id',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
    PERMISSION: 'user_role.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
