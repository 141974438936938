import {FC, ReactNode} from 'react'
import {IOnboarding} from 'src/app/models/Onboarding/Onboarding.models'
import Topbar from '../Topbar/Topbar'

interface ITopLayout extends IOnboarding {
  children: ReactNode
}

const TopLayout: FC<ITopLayout> = ({state, children}) => {
  return (
    <>
      <Topbar state={state} />
      <div className='flex justify-center w-full min-h-screen py-32 bg-[#FBFDFE]'>
        <div className='w-[900px]'>{children}</div>
      </div>
    </>
  )
}

export default TopLayout
