import axios from 'axios'
import {BaseResponse, ITask, ITaskQuery, ITaskSubmision, Pagination, Response} from 'src/app/models'

export const TASK = '/api/v1/admins/tasks'

//react-query key
export const LIST_TASK_KEY = 'list-tasks'
export const DETAIL_TASK_KEY = 'detail-tasks'
export const DETAIL_TASK_SUBMISSION_KEY = 'detail-task-submission'
export const DETAIL_TASK_REVIEW = 'detail-task-review'

export const getListTask = (params?: ITaskQuery) => {
  return axios.get<BaseResponse<Pagination<ITask[]>>>(TASK, {
    params,
  })
}

export const getDetailTask = (id?: string) => {
  return axios.get<BaseResponse<Response<ITask>>>(`${TASK}/${id}`)
}

export const saveTask = (data?: any, id?: string) => {
  if (id) return axios.put(`${TASK}/${id}`, data)
  return axios.post(TASK, data)
}

export const deleteTask = (id?: string) => {
  return axios.delete(`${TASK}/${id}`)
}

export const closeTask = (data?: any, id?: string) => {
  return axios.put(`${TASK}/${id}/close`, data)
}

export const updateTaskStatus = (data?: any, id?: string) => {
  return axios.put(`${TASK}/${id}/status`, data)
}

export const saveFollowUp = (data?: any) => {
  return axios.post(`${TASK}/follow-up`, data)
}

export const getTaskReview = () => {
  return axios.get<BaseResponse<Response<{need_to_review_count: number}>>>(`${TASK}/need-to-review`)
}

export const getDetailTaskSubmission = (id?: string) => {
  return axios.get<BaseResponse<Response<ITaskSubmision>>>(`${TASK}/${id}/submission-details`)
}
