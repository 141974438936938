import axios from 'axios'
import {
  BaseResponse,
  ICity,
  ICityQuery,
  IDistrict,
  IDistrictQuery,
  IProvince,
  IProvinceQuery,
  IVillage,
  IVillageQuery,
  Pagination,
} from 'src/app/models'

export const REGION = '/api/v1/admins/region'
const PROVINCE = 'province'
const CITY = 'city'
const DISTRICT = 'district'
const VILLAGE = 'village'

//react-query key
export const LIST_PROVINCE_KEY = 'list-region-province'
export const LIST_CITY_KEY = 'list-region-city'
export const LIST_DISTRICT_KEY = 'list-region-district'
export const LIST_VILLAGE_KEY = 'list-region-village'

export const getListProvince = (params?: IProvinceQuery) => {
  return axios.get<BaseResponse<Pagination<IProvince[]>>>(`${REGION}/${PROVINCE}`, {
    params,
  })
}

export const getListCity = (params?: ICityQuery) => {
  return axios.get<BaseResponse<Pagination<ICity[]>>>(`${REGION}/${CITY}`, {
    params,
  })
}

export const getListDistrict = (params?: IDistrictQuery) => {
  return axios.get<BaseResponse<Pagination<IDistrict[]>>>(`${REGION}/${DISTRICT}`, {
    params,
  })
}

export const getListVillage = (params?: IVillageQuery) => {
  return axios.get<BaseResponse<Pagination<IVillage[]>>>(`${REGION}/${VILLAGE}`, {
    params,
  })
}
