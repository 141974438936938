import axios from 'axios'
import {BaseResponse, IForm, IFormQuery, Pagination, Response} from 'src/app/models'

export const FORM = '/api/v1/admins/forms'

//react-query key
export const LIST_FORM_KEY = 'list-forms'
export const DETAIL_FORM_KEY = 'detail-forms'

export const getListForm = (params?: IFormQuery) => {
  return axios.get<BaseResponse<Pagination<IForm[]>>>(FORM, {
    params,
  })
}

export const getDetailForm = (id?: string) => {
  return axios.get<BaseResponse<Response<IForm>>>(`${FORM}/${id}`)
}

export const saveForm = (data?: any, id?: string) => {
  if (id) return axios.put(`${FORM}/${id}`, data)
  return axios.post(FORM, data)
}

export const deleteForm = (id?: string) => {
  return axios.delete(`${FORM}/${id}`)
}
