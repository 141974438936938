import {IOrganization, IStatusData, Order} from 'src/app/models'

export interface ICompany extends IStatusData {
  id?: string
  organization_code?: string
  name?: string
  type: CompanyType
  is_active?: boolean
  organization_id?: string
  organization?: IOrganization
}

export interface ICompanyQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
  type?: CompanyType
  is_active?: boolean
}

export enum CompanyType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}
