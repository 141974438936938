const OnboardingScreens = {
  ONBOARDING: {
    PATH: '/activation',
    TITLE: 'Activation',
    TITLE_ALT: 'Activation',
  },
  ONBOARDING_SUCCESS: {
    PATH: '/activation/success',
    TITLE: 'Activation Success',
    TITLE_ALT: 'Activation Success',
  },
}

export default OnboardingScreens
