import {ScreenProp} from 'src/app/models'
import {AgentListScreen} from './page/agent-list/Screen'

const AgentManagementScreens: Record<string, ScreenProp> = {
  AGENT_MANAGEMENT: {
    PATH: '/agent-management',
    TITLE: 'Agent Management',
    TITLE_ALT: 'Agent Management',
  },

  ...AgentListScreen,
}

export default AgentManagementScreens
