import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {cn} from 'src/app/utils/cn-utils'
import {Card} from '../../Libs'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import {AsideMenu} from './AsideMenu'

const AsideDefault: FC = () => {
  const {minimize, setMinimize} = useMasterLayout()
  const [isHovering, setIsHovering] = useState(false)
  const onMouseEnter = () => {
    if (minimize)
      setTimeout(() => {
        setIsHovering(true)
      }, 100)
  }
  const onMouseLeave = () => setIsHovering(false)

  return (
    <div
      className={cn(
        'bg-gradient-to-br from-primary-700 to-primary-600',
        'fixed top-0 left-0 bottom-0 z-[110] transition-all duration-300 group flex flex-col -translate-x-full',
        'lg:translate-x-0',
        {'lg:w-[72px] ': minimize},
        {' lg:hover:w-[264px]': minimize && isHovering},
        {'translate-x-0 w-[264px]': !minimize}
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* LOGO  */}
      <div className='relative z-10 flex items-center'>
        <div className={cn('flex items-center h-[80px] pl-[18px] py-[13px]')}>
          <Link to='/dashboard' className={cn('relative flex items-center')}>
            {minimize ? (
              <>
                {isHovering ? (
                  <img
                    src={toAbsoluteUrl('/logo_optera_white.svg')}
                    alt='optera_logo'
                    className='h-14'
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl('/logo_optera_white_small.svg')}
                    alt='ait_logo'
                    className='w-8 h-8'
                  />
                )}
              </>
            ) : (
              <img
                src={toAbsoluteUrl('/logo_optera_white.svg')}
                alt='optera_logo'
                className='h-14'
              />
            )}
          </Link>
        </div>

        {/* TOGGLER */}
        <Card.Root
          data-testid='toggle-button'
          onClick={() => {
            setMinimize(!minimize)
            setIsHovering(false)
          }}
          className={cn(
            'cursor-pointer z-1 absolute w-8 h-8 rounded-full top-50 right-0 translate-x-[50%] items-center justify-center border border-neutral-200',
            'lg:flex',
            {hidden: minimize},
            {flex: !minimize}
          )}
        >
          <KTSVG
            path={'/media/icons/IconArrowLeftBar.svg'}
            className={cn('h-24px w-24px transition-all duration-300', {
              'rotate-180': minimize,
            })}
          />
        </Card.Root>
      </div>

      {/* begin::Aside menu */}
      <div className='z-10 flex flex-col flex-1 w-auto h-full p-0 m-0'>
        <AsideMenu />
      </div>
      {/* end::Aside menu */}

      {/* STYLING PURPOSE */}
      <div className='absolute right-0 w-full h-full overflow-hidden'>
        <div className='absolute w-[675px] h-[595px] bg-gradient-to-br to-[#EFF4FF]/15 from-[#E5E5E5]/15 -rotate-[45deg] -right-[35%] -top-[20%] z-[1]'></div>
        <div className='absolute w-[675px] h-[595px] bg-gradient-to-br from-[#EFF4FF]/5 to-[#E5E5E5]/5  -rotate-[45deg] -right-[35%] -top-[42%] z-[2]'></div>
        <div className='absolute w-[675px] h-[595px] bg-gradient-to-br from-[#F2F4F7]/5 to-[#F1F9FE]/5 -rotate-[45deg] -right-[35%] -top-[64%] z-[3]'></div>
      </div>
    </div>
  )
}

export default AsideDefault
