import {FC, useEffect} from 'react'
import InlineSVG from 'react-inlinesvg'
import {useDispatch} from 'react-redux'
import {GButton} from 'src/app/components/Libs'
import {IOnboarding} from 'src/app/models/Onboarding/Onboarding.models'
import AuthScreens from 'src/app/modules/auth/AuthScreens'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import TopLayout from '../Layout/TopLayout'

const SuccessSection: FC<IOnboarding> = ({state}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(OnboardingRedux.actions.setEmpty())
    //eslint-disable-next-line
  }, [])

  return (
    <TopLayout state={state}>
      <div className='flex flex-col items-center justify-center w-full h-full bg-neutral-25'>
        <InlineSVG src={toAbsoluteUrl('/media/image/image_success_onboarding.svg')} />
        <div className='mt-6 font-medium text-center text-neutral-900 text-fs-4 w-[522px]'>
          Selamat Anda telah berhasil
          <br /> menyelesaikan proses onboarding
        </div>
        <div className='mt-2 text-center text-neutral-600 text-fs-7 w-[522px]'>
          Silakan login terlebih dahulu, untuk bisa melanjutkan proses lainnya.
        </div>
        <div className='mt-6'>
          <GButton
            href={AuthScreens.LOGIN_EMAIL.PATH}
            size={'large'}
            className='min-w-[89px] h-[56px] text-fs-7'
          >
            Login
          </GButton>
        </div>
      </div>
    </TopLayout>
  )
}

export default SuccessSection
