import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const UserScreen: Record<string, ScreenProp> = {
  USER: {
    PATH: '/administration/setup-user',
    TITLE: 'User List',
    TITLE_ALT: 'Setup User',
    PERMISSION: 'user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  USER_ADD: {
    PATH: '/administration/setup-user/add',
    TITLE: 'Add User',
    TITLE_ALT: 'Add User',
    PERMISSION: 'user.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  USER_EDIT: {
    PATH: '/administration/setup-user/edit/:id',
    TITLE: 'Edit User ',
    TITLE_ALT: 'Edit User',
    PERMISSION: 'user.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  USER_DETAIL: {
    PATH: '/administration/setup-user/detail/:id',
    TITLE: 'Detail User',
    TITLE_ALT: 'Detail User',
    PERMISSION: 'user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
