import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const CompanyListScreen: Record<string, ScreenProp> = {
  COMPANY_LIST: {
    PATH: '/administration/company-list',
    TITLE: 'Company',
    TITLE_ALT: 'Company List',
    PERMISSION: 'company.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_LIST_ADD: {
    PATH: '/administration/company-list/add',
    TITLE: 'Add Company',
    TITLE_ALT: 'Add Company',
    PERMISSION: 'company.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_LIST_EDIT: {
    PATH: '/administration/company-list/edit/:id',
    TITLE: 'Edit Company ',
    TITLE_ALT: 'Edit Company',
    PERMISSION: 'company.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_LIST_DETAIL: {
    PATH: '/administration/company-list/detail/:id',
    TITLE: 'Detail Company ',
    TITLE_ALT: 'Detail Company',
    PERMISSION: 'company.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
