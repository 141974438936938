import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const ReportListScreen: Record<string, ScreenProp> = {
  REPORT_LIST: {
    PATH: '/report/report-list',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
    // PERMISSION: 'report.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
