import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const DynamicFormScreen: Record<string, ScreenProp> = {
  DYNAMIC_FORM: {
    PATH: '/form-management/dynamic-form',
    TITLE: 'Dynamic Form',
    TITLE_ALT: 'Dynamic Form',
    PERMISSION: 'form.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DYNAMIC_FORM_ADD: {
    PATH: '/form-management/dynamic-form/add',
    TITLE: 'Add Form',
    TITLE_ALT: 'Add Form',
    PERMISSION: 'form.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DYNAMIC_FORM_EDIT: {
    PATH: '/form-management/dynamic-form/edit/:id',
    TITLE: 'Edit Form ',
    TITLE_ALT: 'Edit Form',
    PERMISSION: 'form.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DYNAMIC_FORM_DETAIL: {
    PATH: '/form-management/dynamic-form/detail/:id',
    TITLE: 'Detail Form',
    TITLE_ALT: 'Detail Form',
    PERMISSION: 'form.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
