import axios from 'axios'
import {BaseResponse, IService, IServiceQuery, Pagination, Response} from 'src/app/models'

export const SERVICE = '/api/v1/admins/services'

//react-query key
export const LIST_SERVICE_KEY = 'list-services'
export const DETAIL_SERVICE_KEY = 'detail-services'
export const ALLOW_UPDATE_SERVICE_KEY = 'allow-update-services'

export const getListService = (params?: IServiceQuery) => {
  return axios.get<BaseResponse<Pagination<IService[]>>>(SERVICE, {
    params,
  })
}

export const getDetailService = (id?: string) => {
  return axios.get<BaseResponse<Response<IService>>>(`${SERVICE}/${id}`)
}

export const saveService = (data?: any, id?: string) => {
  if (id) return axios.put(`${SERVICE}/${id}`, data)
  return axios.post(SERVICE, data)
}

export const deleteService = (id?: string) => {
  return axios.delete(`${SERVICE}/${id}`)
}

export const allowUpdateDeleteService = (id?: string) => {
  return axios.get<BaseResponse<Response<{is_can_update_delete: boolean}>>>(
    `${SERVICE}/${id}/update-delete-validation`
  )
}
