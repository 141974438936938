import axios from 'axios'
import {BaseResponse, Response} from 'src/app/models'
import {IOnboardingActivation} from 'src/app/models/Onboarding/Onboarding.models'

export const ONBOARDING = `/api/v1/admins/onboarding`
export const VERIFY_ONBOARDING_ACTIVATION = `/api/v1/admins/auth/activation`

export const saveOnboarding = (data?: any) => {
  return axios.post(ONBOARDING, data)
}

export const verifyOnboardingActivation = (params: {t?: string}) => {
  return axios.get<BaseResponse<Response<IOnboardingActivation>>>(
    `${VERIFY_ONBOARDING_ACTIVATION}`,
    {params}
  )
}
