import axios from 'axios'
import {BaseResponse, IProfile, Response} from 'src/app/models'
import {USER} from 'src/app/services'

export const PROFILE = `/api/v1/admins/users/profile`

export const updateProfile = (data?: any) => {
  return axios.put(PROFILE, data)
}

export const updateProfilePassword = (data?: any) => {
  return axios.put(`${PROFILE}/password`, data)
}

export const getProfile = () => {
  return axios.get<BaseResponse<Response<IProfile>>>(`${USER}/profile`)
}
