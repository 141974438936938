import {useFormik} from 'formik'
import {FC, useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {FormLabel, FormText, GButton} from 'src/app/components/Libs'
import {FormPhone} from 'src/app/components/Libs/Form/Custom'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {IOnboarding} from 'src/app/models/Onboarding/Onboarding.models'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {phoneRegExp, phoneStartsWith62And8RegExp} from 'src/app/utils/input-utils'
import * as Yup from 'yup'
import AlertModal from '../AlertModal/AlertModal'
import FormLayout from '../Layout/FormLayout'

const AgentSection: FC<IOnboarding> = ({state}) => {
  const dispatch = useDispatch()
  const [warningModal, setWarningModal] = useState(false)
  const [warningState, setWarningState] = useState<'SKIP' | 'BACK'>()
  const [formValuesChanged, setFormValuesChanged] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: state.agent as OnboardingRedux.TAgentState,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Nama agen wajib diisi')
        .min(3, 'Minimal karakter 3')
        .max(255, 'Maksimal karakter 255'),
      mobile_number: Yup.string()
        .trim()
        .matches(phoneRegExp, 'Nomor telepon harus terdiri dari 10 - 13 digit')
        .matches(phoneStartsWith62And8RegExp, 'Digit pertama harus angka 8')
        .required('Nomor telepon wajib diisi')
        .max(14, 'Nomor telepon harus terdiri dari 10 - 13 digit')
        .test(
          'not-same-as-profile',
          'Nomor telepon tidak boleh sama dengan nomor telepon di admin/agen.',
          function (value) {
            return (
              value !== state?.admin_data?.mobile_number &&
              !state?.users?.some((user) => user.mobile_number === value)
            )
          }
        ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(OnboardingRedux.actions.setAgent(values))
      dispatch(OnboardingRedux.actions.setStep(5))
    },
  })

  const defaultAgent = useMemo(
    () => ({
      name: '',
      mobile_number: '',
    }),
    []
  )

  const handleSkipStep = () => {
    if (formValuesChanged) {
      setWarningModal(true)
      setWarningState('SKIP')
    } else {
      dispatch(OnboardingRedux.actions.setStep(5))
    }
  }

  const handleBackStep = () => {
    const hasFormValuesChanged = Object.values(formik.values).some((value, index) => {
      return (
        JSON.stringify(value) !==
        JSON.stringify(Object.values(state.agent as OnboardingRedux.TAgentState)[index])
      )
    })

    if (hasFormValuesChanged) {
      setWarningModal(true)
      setWarningState('BACK')
    } else {
      dispatch(OnboardingRedux.actions.setStep(3))
    }
  }

  const handleWarningStep = () => {
    if (warningState === 'BACK') {
      dispatch(OnboardingRedux.actions.setStep(3))
    }
    if (warningState === 'SKIP') {
      dispatch(OnboardingRedux.actions.setAgent(defaultAgent))
      dispatch(OnboardingRedux.actions.setStep(5))
    }
  }

  useEffect(() => {
    const hasFormValuesChanged = Object.values(formik.values).some((value, index) => {
      return JSON.stringify(value) !== JSON.stringify(Object.values(defaultAgent)[index])
    })

    setFormValuesChanged(hasFormValuesChanged)
  }, [formik.values, defaultAgent])

  return (
    <FormLayout state={state}>
      <form
        className='w-full bg-white rounded shadow-lg pt-9'
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div
          onClick={handleSkipStep}
          className='px-10 font-medium underline cursor-pointer text-end text-primary-500'
        >
          Lewati
        </div>

        <div className='w-full px-10 mt-10'>
          <div className='font-semibold text-fs-6 text-neutral-900'>Tambahkan Agen</div>
          <div className='mt-[6px] text-neutral-600'>
            Anda bisa menambahkan agen lain di menu <span className='font-semibold'>user</span>{' '}
            setelah proses onboarding selesai.
          </div>
        </div>

        <div className='w-full py-10 px-9'>
          <div>
            <FormLabel className='mb-2' required>
              Nama agen
            </FormLabel>
            <FormText
              {...formik.getFieldProps(`name`)}
              name={`name`}
              placeholder='Masukkan nama agen'
              maxLength={255}
              inputClassName='bg-white'
              error={formik.errors.name}
              touched={formik.touched.name}
            />
            <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
              <div>
                <GIcon icon='IconInfoOutline' />
              </div>
              Nama lengkap agen sesuai identitas KTP
            </div>
          </div>

          <div className='mt-6'>
            <FormLabel className='mb-2' required>
              Nomor telepon
            </FormLabel>
            <FormPhone
              onChangeValue={(value) => formik.setFieldValue(`mobile_number`, value)}
              placeholder='Contoh: 81234567000'
              value={formik.values.mobile_number as string}
              inputClassName='bg-white'
              error={formik.errors.mobile_number}
              touched={formik.touched.mobile_number}
            />
            <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
              <div>
                <GIcon icon='IconInfoOutline' />
              </div>
              Pastikan nomor telepon terdaftar di WhatsApp
            </div>
          </div>
        </div>

        <div className='w-full border-t border-neutral-200'>
          <div className='flex items-center justify-end gap-4 px-10 py-5'>
            <GButton onClick={handleBackStep} size={'large'} type='button' variant='OUTLINED'>
              Back
            </GButton>
            <GButton size={'large'} type='submit'>
              Lanjut
            </GButton>
          </div>
        </div>
      </form>

      <AlertModal modal={warningModal} setModal={setWarningModal} onLeave={handleWarningStep} />
    </FormLayout>
  )
}

export default AgentSection
