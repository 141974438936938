import {EFormJobType} from 'src/app/models'
import * as Yup from 'yup'

export const validation = Yup.object().shape({
  name: Yup.string()
    .required('Nama job wajib diisi')
    .min(3, 'Minimal karakter 3')
    .max(255, 'Maksimal karakter 255'),
  forms: Yup.array().of(
    Yup.object().shape({
      question: Yup.string()
        .required('Pertanyaan wajib diisi')
        .min(3, 'Minimal karakter 3')
        .max(255, 'Maksimal karakter 255'),
      type: Yup.string().required('Bentuk laporan tugas wajib dipilih'),
      description: Yup.string()
        .required('Deskripsi tugas wajib diisi')
        .min(3, 'Minimal karakter 3')
        .max(255, 'Maksimal karakter 255'),
      data: Yup.array()
        .when('type', {
          is: (type: EFormJobType) =>
            [EFormJobType.DROPDOWN, EFormJobType.MULTIPLE_CHOICE].includes(type),
          then: (schema) =>
            schema.of(
              Yup.object().shape({
                id: Yup.string().required(),
                name: Yup.string().required('Pilihan wajib diisi'),
              })
            ),
          otherwise: (schema) => schema.nullable(),
        })
        .nullable(),
    })
  ),
})
