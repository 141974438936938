import GIcon from 'src/app/components/Libs/Icon/GIcon'

const CustomToast = ({closeToast}: any) => (
  <div className='p-4 w-[520px] h-[56px] rounded-lg flex items-center justify-between border bg-danger-50 border-danger-300'>
    <div className='flex items-center gap-3'>
      <div className='text-danger-500'>
        <GIcon icon='IconInfoOutline' className='w-6 h-6' />
      </div>
      <div className='text-neutral-600 text-fs-9'>Anda sedang tidak terhubung ke internet.</div>
    </div>
    <div className='text-danger-500' onClick={closeToast}>
      <GIcon icon='IconClose' className='w-6 h-6 cursor-pointer' />
    </div>
  </div>
)

export default CustomToast
