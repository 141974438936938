import axios from 'axios'
import {BaseResponse, IDropdown, IDropdownQuery, Pagination} from 'src/app/models'

type IDropdownResponse = BaseResponse<Pagination<IDropdown[]>>

export const DROPDOWN = '/api/v1/admins/dropdown'

export const getDropdownExportType = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/export/export_type`, {params})
}

export const getDropdownExportModule = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/export/export_module`, {params})
}

export const getDropdownCompany = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/companies`, {params})
}

export const getDropdownDepartment = (params?: IDropdownQuery, company_id?: string) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/companies/${company_id}/departments`, {params})
}

export const getDropdownService = (
  params?: IDropdownQuery,
  company_id?: string,
  department_id?: string
) => {
  return axios.get<IDropdownResponse>(
    `${DROPDOWN}/companies/${company_id}/departments/${department_id}/services`,
    {params}
  )
}
