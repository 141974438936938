import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import LocalizationRedux from 'src/app/plugins/i18n/localization-redux'
import AuthRedux from 'src/app/store/Auth/AuthRedux'
import GlobalParamRedux from 'src/app/store/Global/GlobalParamRedux'
import OnboardingRedux from './Onboarding/OnboardingRedux'

export const rootReducer = () =>
  combineReducers({
    auth: AuthRedux.reducer(),
    localization: LocalizationRedux.reducer(),
    global: GlobalParamRedux.reducer(),
    onboarding: OnboardingRedux.reducer(),
  })

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export function* rootSaga() {
  yield all([AuthRedux.saga()])
}
