import axios from 'axios'
import {
  BaseResponse,
  IRole,
  IRoleAccessTemplate,
  IRoleDetail,
  IRoleQuery,
  Pagination,
  Response,
} from 'src/app/models'

export const ROLE = '/api/v1/admins/user-roles'
export const ROLE_ACCESS_TEMPLATE = '/api/v1/admins/user-roles/access-template'

//react-query key
export const LIST_ROLE_KEY = 'list-role'
export const DETAIL_ROLE_KEY = 'detail-role'
export const ACCESS_TEMPLATE_KEY = 'access-template-role'

export const getListRole = (params?: IRoleQuery) => {
  return axios.get<BaseResponse<Pagination<IRole[]>>>(ROLE, {
    params,
  })
}

export const getDetailRole = (id?: string) => {
  return axios.get<BaseResponse<Response<IRoleDetail>>>(`${ROLE}/${id}`)
}

export const saveRole = (data?: any, id?: string) => {
  if (id) return axios.put(`${ROLE}/${id}`, data)
  return axios.post(ROLE, data)
}

export const deleteRole = (id?: string) => {
  return axios.delete(`${ROLE}/${id}`)
}

export const getListAccessTemplateRole = (params?: {
  user_type: 'ORGANIZATION' | 'DEPARTMENT' | 'USER'
}) => {
  return axios.get<BaseResponse<Response<IRoleAccessTemplate[]>>>(ROLE_ACCESS_TEMPLATE, {params})
}
