import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const DepartmentListScreen: Record<string, ScreenProp> = {
  DEPARTMENT_LIST: {
    PATH: '/administration/department-list',
    TITLE: 'Department',
    TITLE_ALT: 'Department List',
    PERMISSION: 'department.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DEPARTMENT_LIST_ADD: {
    PATH: '/administration/department-list/add',
    TITLE: 'Add Department',
    TITLE_ALT: 'Add Department',
    PERMISSION: 'department.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DEPARTMENT_LIST_EDIT: {
    PATH: '/administration/department-list/edit/:id',
    TITLE: 'Edit Department ',
    TITLE_ALT: 'Edit Department',
    PERMISSION: 'department.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DEPARTMENT_LIST_DETAIL: {
    PATH: '/administration/department-list/detail/:id',
    TITLE: 'Detail Department ',
    TITLE_ALT: 'Detail Department',
    PERMISSION: 'department.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}
