import {ScreenProp} from 'src/app/models'
import {ReportListScreen} from './page/report-list/Screen'

const ReportScreens: Record<string, ScreenProp> = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
  },

  ...ReportListScreen,
}

export default ReportScreens
