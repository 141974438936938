import axios from 'axios'
import {BaseResponse, IOrganization, IOrganizationQuery, Pagination, Response} from 'src/app/models'

export const ORGANIZATION = '/api/v1/admins/organizations'

//react-query key
export const LIST_ORGANIZATION_KEY = 'list-organization'
export const DETAIL_ORGANIZATION_KEY = 'detail-organization'
export const ALLOW_UPDATE_ORGANIZATION_KEY = 'allow-update-organization'

export const getListOrganization = (params?: IOrganizationQuery) => {
  return axios.get<BaseResponse<Pagination<IOrganization[]>>>(ORGANIZATION, {
    params,
  })
}

export const getDetailOrganization = (id?: string) => {
  return axios.get<BaseResponse<Response<IOrganization>>>(`${ORGANIZATION}/${id}`)
}

export const saveOrganization = (data?: any, id?: string) => {
  if (id) return axios.put(`${ORGANIZATION}/${id}`, data)
  return axios.post(ORGANIZATION, data)
}

export const deleteOrganization = (id?: string) => {
  return axios.delete(`${ORGANIZATION}/${id}`)
}

export const allowUpdateDeleteOrganization = (id?: string) => {
  return axios.get<BaseResponse<Response<{is_can_update_delete: boolean}>>>(
    `${ORGANIZATION}/${id}/update-delete-validation`
  )
}

// react-select-api
export const selectListOrganization = async (search: string, loadedOptions: any) => {
  try {
    const payload = await getListOrganization({
      page: Math.ceil(loadedOptions.length / 10),
      size: 10,
      search: search,
    })

    const data = payload.data.response_output?.list?.content ?? []

    const options = data?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))

    return {
      options,
      hasMore:
        options.length + loadedOptions.length <
        (payload.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}
