import {cva} from 'class-variance-authority'

const sizes = {
  small: ['py-[8px]', 'px-[16px]', 'h-[32px]', 'text-fs-10'],
  medium: ['py-[9px]', 'px-[16px]', 'h-[38px]', 'text-fs-9'],
  large: ['py-[10px]', 'px-[20px]', 'h-[44px]', 'text-fs-8'],
}

export const filledButton = cva(
  [
    'border',
    'outline-none',
    'font-medium',
    'rounded-lg',
    'transition-all',
    'duration-300',
    'disabled:opacity-40',
    'min-w-[116px]',
  ],
  {
    variants: {
      colors: {
        primary: ['bg-primary-500', 'border-primary-500', 'hover:bg-primary-600', 'text-white'],
        success: ['bg-success-500', 'border-success-500', 'hover:bg-success-600', 'text-white'],
        danger: ['bg-danger-500', 'border-danger-500', 'hover:bg-danger-600', 'text-white'],
        warning: ['bg-warning-500', 'border-warning-500', 'hover:bg-warning-600', 'text-white'],
        info: ['bg-info-500', 'border-info-500', 'hover:bg-info-600', 'text-white'],
      },
      size: sizes,
    },
    compoundVariants: [
      {
        colors: 'primary',
        size: 'medium',
      },
    ],
    defaultVariants: {
      colors: 'primary',
      size: 'medium',
    },
  }
)

export const outlinedButton = cva(
  [
    'border',
    'outline-none',
    'font-medium',
    'rounded-lg',
    'transition-all',
    'duration-300',
    'disabled:bg-[#f1f3f5]',
    'disabled:text-[#adb5bd]',
    'disabled:border-[#f1f3f5]',
    'min-w-[116px]',
  ],
  {
    variants: {
      colors: {
        primary: ['text-primary-500', 'border-primary-500', 'hover:bg-primary-25'],
        success: ['text-success-500', 'border-success-500', 'hover:bg-success-25'],
        danger: ['text-danger-500', 'border-danger-500', 'hover:bg-danger-25'],
        warning: ['text-warning-500', 'border-warning-500', 'hover:bg-warning-25'],
        info: ['text-info-500', 'border-info-500', 'hover:bg-info-25'],
      },
      size: sizes,
    },
    compoundVariants: [
      {
        colors: 'primary',
        size: 'medium',
      },
    ],
    defaultVariants: {
      colors: 'primary',
      size: 'medium',
    },
  }
)

export const ghostButton = cva(
  [
    'border-none',
    'outline-none',
    'font-medium',
    'rounded-lg',
    'transition-all',
    'duration-300',
    'disabled:text-[#adb5bd]',
    'min-w-[116px]',
  ],
  {
    variants: {
      colors: {
        primary: ['text-primary', 'hover:bg-primary-200'],
        success: ['text-success', 'hover:bg-success-200'],
        danger: ['text-danger', 'hover:bg-danger-200'],
        warning: ['text-warning', 'hover:bg-warning-200'],
        info: ['text-info', 'hover:bg-info-200'],
      },
      size: sizes,
    },
    compoundVariants: [
      {
        colors: 'primary',
        size: 'medium',
      },
    ],
    defaultVariants: {
      colors: 'primary',
      size: 'medium',
    },
  }
)

export const unstyledButton = cva(
  ['p-0', 'font-medium', 'bg-transparent', 'border-0', 'outline-0'],
  {
    variants: {
      size: sizes,
    },
    compoundVariants: [
      {
        size: 'medium',
      },
    ],
    defaultVariants: {
      size: 'medium',
    },
  }
)
