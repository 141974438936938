import axios from 'axios'
import {BaseResponse, IReport, IReportQuery, Pagination} from 'src/app/models'

export const REPORT = '/api/v1/admins/reports'

//react-query key
export const LIST_REPORT_KEY = 'list-report'
export const DETAIL_REPORT_KEY = 'detail-report'

export const getListReport = (params?: IReportQuery) => {
  return axios.get<BaseResponse<Pagination<IReport[]>>>(REPORT, {
    params,
  })
}

export const downloadReport = (data?: any) => {
  return axios
    .post(`${REPORT}/download`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
    .then((response) => {
      return {
        data: response.data,
        headers: response.headers,
      }
    })
}
