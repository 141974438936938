import {FC, useEffect} from 'react'
import InlineSVG from 'react-inlinesvg'
import {useDispatch} from 'react-redux'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

const ExpiredSection: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(OnboardingRedux.actions.setEmpty())
    //eslint-disable-next-line
  }, [])

  return (
    <div className='flex flex-col items-center justify-center w-full h-full bg-neutral-25'>
      <InlineSVG src={toAbsoluteUrl('/media/image/image_expired_link.svg')} />
      <div className='mt-6 font-medium text-center text-neutral-900 text-fs-4 w-[522px]'>
        Link sudah kedaluwarsa
      </div>
      <div className='mt-2 text-center text-neutral-600 text-fs-7 w-[522px]'>
        Untuk dapat melanjutkan onboarding, silakan hubungi admin OpteraOne yang mengundang Anda.
        <br />
        Link baru tersebut hanya akan aktif selama 24 jam.
      </div>
    </div>
  )
}

export default ExpiredSection
