import {FC, useState} from 'react'
import {FormText, FormTextProps} from 'src/app/components/Libs'
import GIcon from 'src/app/components/Libs/Icon/GIcon'

export interface PasswordFormUniqueProps extends FormTextProps {
  defaultVisibility?: boolean
}

export const iconCheck = (payload: boolean, passwordValue: string, error?: string) => {
  if (passwordValue.length !== 0) {
    if (payload) return 'IconCheckCircle'
    else return 'IconCrossCircle'
  } else {
    if (error) return 'IconCrossCircle'
    return 'IconCheckCircle'
  }
}

export const colorCheck = (payload: boolean, passwordValue: string, error?: string) => {
  if (passwordValue.length !== 0) {
    if (payload) return 'text-success-500'
    else return 'text-danger-500'
  } else {
    if (error) return 'text-danger-500'
    return 'text-neutral-500'
  }
}

export const PasswordFormUnique: FC<PasswordFormUniqueProps> = ({
  defaultVisibility = false,
  ...rest
}) => {
  const [visible, setVisible] = useState(defaultVisibility)

  const passwordValue = rest.value as string

  const validations = [
    {
      check: passwordValue.length >= 8,
      message: 'Minimal 8 karakter',
    },
    {
      check: /\d/.test(passwordValue),
      message: 'Minimal 1 angka',
    },
    // {
    //   check: /[A-Z]/.test(passwordValue),
    //   message: 'Minimal 1 huruf kapital',
    // },
    {
      check: /[!@#$&*%^()_-]/.test(passwordValue),
      message: 'Minimal 1 karakter khusus, contoh: /[!@#$&*%^()_-]',
    },
  ]

  return (
    <div>
      <FormText
        type={visible ? 'text' : 'password'}
        appendInnerIcon={visible ? 'IconEyeOff' : 'IconEyeOn'}
        clickAppendInnerIcon={() => setVisible(!visible)}
        appendInnerClassname='text-neutral-500'
        {...rest}
      />

      <div className='grid grid-cols-1 mt-3 text-fs-10 gap-y-2 text-neutral-500'>
        <div className='text-fs-9'>Ketentuan password</div>
        {validations.map((val) => (
          <div key={val.message} className='flex gap-x-2'>
            <GIcon
              icon={iconCheck(val.check, passwordValue, rest.error)}
              className={colorCheck(val.check, passwordValue, rest.error)}
            />
            <span className='text-neutral-500'>{val.message}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
