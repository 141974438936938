import axios, {AxiosRequestConfig} from 'axios'
import {BaseResponse, IAgent, IAgentQuery, Pagination, Response} from 'src/app/models'

export const AGENT = '/api/v1/admins/agents'

//react-query key
export const LIST_AGENT_KEY = 'list-agents'
export const DETAIL_AGENT_KEY = 'detail-agents'

const multiFormDataHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const getListAgent = (params?: IAgentQuery) => {
  return axios.get<BaseResponse<Pagination<IAgent[]>>>(AGENT, {
    params,
  })
}

export const getDetailAgent = (id?: string) => {
  return axios.get<BaseResponse<Response<IAgent>>>(`${AGENT}/${id}`)
}

export const saveAgent = (data?: any, id?: string) => {
  if (id) return axios.put(`${AGENT}/${id}`, data, {...multiFormDataHeaders})
  return axios.post(AGENT, data, {...multiFormDataHeaders})
}

export const deleteAgent = (id?: string) => {
  return axios.delete(`${AGENT}/${id}`)
}

export const validateAgentFile = (data?: any, config?: AxiosRequestConfig) => {
  return axios.post(`${AGENT}/validate-import`, data, config)
}

export const importAgentFile = (data?: any, config?: AxiosRequestConfig) => {
  return axios.post(`${AGENT}/import`, data, config)
}
