import {EFormJobType} from '../models'

export const formJobTypeList: Record<EFormJobType, string> = {
  [EFormJobType.TEXT]: 'Text Field',
  [EFormJobType.MULTIPLE_CHOICE]: 'Multiple Choice',
  [EFormJobType.DROPDOWN]: 'Dropdown',
  [EFormJobType.IMAGE]: 'Image',
  [EFormJobType.DATE]: 'Date',
  [EFormJobType.TIME]: 'Time',
}

export const formJobTypeOpt = Object.entries(formJobTypeList).map(([key, label]) => ({
  value: key as EFormJobType,
  label,
}))
