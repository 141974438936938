import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {useModal} from 'src/app/components/Libs/Modal/Modal.hooks'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {cn} from 'src/app/utils/cn-utils'
import {v4 as uuidv4} from 'uuid'

const RowData = ({menu, capabilities, state}: any) => (
  <>
    {capabilities.map((capability: any, index: any) => (
      <tr
        key={uuidv4()}
        className='bg-white rounded-lg border-neutral-200 last:border-b text-fs-10'
      >
        {index === 0 && (
          <td
            className='px-6 py-3 align-top border-b text-neutral-800'
            rowSpan={capabilities.length}
          >
            <div className='py-3'>{menu}</div>
          </td>
        )}
        <td className='px-6 py-3 border-b'>{capability.name}</td>
        {capability.levels.map((level: any, idx: any) => {
          // Conditionally render levels based on state.level
          if (state?.profile?.level === 'L2' && idx === 0) return null
          return (
            <td key={uuidv4()} className='px-6 py-3 text-center border-b place-items-center'>
              {level ? (
                <div className='flex items-center justify-center w-10 h-10 text-center rounded-full bg-success-100'>
                  <InlineSVG src={toAbsoluteUrl('/media/icons/IconCheckGreen.svg')} />
                </div>
              ) : (
                <div className='flex items-center justify-center w-10 h-10 rounded-full bg-danger-100'>
                  <InlineSVG src={toAbsoluteUrl('/media/icons/IconCrossRed.svg')} />
                </div>
              )}
            </td>
          )
        })}
      </tr>
    ))}
  </>
)

const TableComponent = ({data, state}: any) => (
  <table className='w-full mb-20 border-separate table-auto border-spacing-y-2'>
    <thead className='sticky top-0 font-medium border-b bg-neutral-50 text-neutral-500 border-neutral-200 text-fs-10'>
      <tr>
        <th className='px-6 py-3 text-left'>Menu</th>
        <th className='px-6 py-3 text-left'>Kapabilitas</th>
        {state?.profile?.level !== 'L2' && <th className='px-6 py-3'>Level 1</th>}
        <th className='px-6 py-3'>Level 2</th>
        <th className='px-6 py-3'>Level 3</th>
      </tr>
    </thead>
    <tbody className='text-neutral-800 text-fs-9'>
      {data.map((item: any) => (
        <RowData key={item.menu} menu={item.menu} capabilities={item.capabilities} state={state} />
      ))}
    </tbody>
  </table>
)

const AdminDetailLevel: FC<any> = ({show, onHide, state}) => {
  const {temporaryShow, animateClose} = useModal(show)
  if (!temporaryShow) return null

  const data = [
    {
      menu: 'Dashboard',
      capabilities: [
        {name: 'Lihat Insight', levels: [true, true, true]},
        {name: 'Export Insight', levels: [true, true, false]},
      ],
    },
    {
      menu: 'Job & Tugas',
      capabilities: [
        {name: 'Lihat Tugas', levels: [true, true, true]},
        {name: 'Tambah/Ubah Tugas', levels: [true, true, false]},
        {name: 'Download Tugas', levels: [true, true, false]},
        {name: 'Lihat Template Tugas', levels: [true, true, true]},
        {name: 'Tambah/Ubah Template Tugas', levels: [true, true, false]},
      ],
    },
    {
      menu: 'User',
      capabilities: [
        {name: 'Lihat Agen', levels: [true, true, true]},
        {name: 'Tambah/Ubah Agen', levels: [true, true, false]},
        {name: 'Import Agen', levels: [true, true, false]},
        {name: 'Lihat Admin', levels: [true, true, true]},
        {name: 'Tambah/Ubah Admin Level 1', levels: [true, false, false]},
        {name: 'Tambah/Ubah Admin Level 2', levels: [true, true, false]},
        {name: 'Tambah/Ubah Admin Level 3', levels: [true, true, false]},
        {name: 'Import Admin', levels: [true, true, false]},
        {name: 'Lihat Perizinan', levels: [true, true, false]},
        {name: 'Tambah/Ubah Perizinan', levels: [true, false, false]},
      ],
    },
    {
      menu: 'Pengaturan',
      capabilities: [
        {name: 'Pengaturan Umum', levels: [true, false, false]},
        {name: 'Pengaturan Perusahaan Saya', levels: [true, false, false]},
        {name: 'Lihat/Ubah Paket & Penagihan', levels: [true, false, false]},
        {name: 'Pemberitahuan', levels: [true, false, false]},
        {name: 'Bantuan & Data Privasi', levels: [true, false, false]},
      ],
    },
  ]

  return (
    <div className='fixed top-0 right-0 left-0 bottom-0 z-[9999] flex items-center justify-end'>
      <div onClick={onHide} className='absolute top-0 bottom-0 left-0 right-0 bg-black/50'></div>

      <div
        className={cn(
          'relative w-[800px] bg-white h-screen z-10 transition-all',
          'animate-admin-detail-open',
          {
            'animate-admin-detail-close': animateClose,
          }
        )}
      >
        <div className='fixed left-0 right-0 flex items-center justify-between px-6 py-5 max-h-[72px] shadow'>
          <div className='font-semibold text-neutral-900 text-fs-6'>Detail Level Admin</div>
          <GIcon icon='IconClose' className='w-6 h-6 cursor-pointer' onClick={onHide} />
        </div>

        <div className='mt-[72px] overflow-scroll h-full'>
          <div className='flex items-center px-6 py-4 text-neutral-500 text-fs-9 gap-x-2'>
            <GIcon icon='IconInfoOutline' />
            <div>
              Ini adalah pengaturan default, Anda bisa melakukan kostumasi setelah onboarding
              selesai
            </div>
          </div>

          <TableComponent data={data} state={state} />
        </div>
      </div>
    </div>
  )
}

export default AdminDetailLevel
