import {ScreenProp} from 'src/app/models'
import {DynamicFormScreen} from './page/dynamic-form/Screen'

const FormManagementScreens: Record<string, ScreenProp> = {
  FORM_MANAGEMENT: {
    PATH: '/form-management',
    TITLE: 'Form Management',
    TITLE_ALT: 'Form Management',
  },

  ...DynamicFormScreen,
}

export default FormManagementScreens
