import {useMemo} from 'react'
import {IUser, ModulePermissionQuery} from 'src/app/models'
import AdministrationScreens from 'src/app/modules/administration/AdministrationScreen'
import AgentManagementScreens from 'src/app/modules/agent-management/AgentManagementScreen'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
// import FormManagementScreens from 'src/app/modules/form-management/FormManagementScreen'
// import MasterScreens from 'src/app/modules/master/MasterScreen'
// import ReportScreens from 'src/app/modules/report/ReportScreen'
import TaskManagementScreens from 'src/app/modules/task-management/TaskManagementScreen'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

// new sidebar menu

export const useMenus = (user?: IUser): Menu[] => {
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              hidden: user?.role?.user_type === 'SUPERADMIN',
            },

            {
              to: AgentManagementScreens.AGENT_MANAGEMENT.PATH,
              icon: '/media/icons/IconUserFriends.svg',
              title: 'User',
              hidden: user?.role?.user_type === 'SUPERADMIN',
              children: [
                {
                  to: AdministrationScreens.USER.PATH,
                  activePath: AdministrationScreens.USER.PATH,
                  title: 'Admin',
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'SUPERADMIN',
                },
                {
                  to: AgentManagementScreens.AGENT_LIST.PATH,
                  activePath: AgentManagementScreens.AGENT_LIST.PATH,
                  title: 'Agen',
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'SUPERADMIN',
                },
              ],
            },

            {
              to: TaskManagementScreens.TASK_LIST.PATH,
              icon: '/media/icons/IconTaskManagement.svg',
              title: 'Job & Tugas',
              hidden: user?.role?.user_type === 'SUPERADMIN',
            },

            // {
            //   to: '/setting',
            //   icon: '/media/icons/IconSetting.svg',
            //   title: 'Pengaturan',
            //   hidden: user?.role?.user_type === 'SUPERADMIN',
            // },

            // UNTUK ADMIN L0
            {
              to: AdministrationScreens.ADMINISTRATION.PATH,
              icon: '/media/icons/IconAdministration.svg',
              title: AdministrationScreens.ADMINISTRATION.TITLE,
              hidden: user?.role?.user_type !== 'ORGANIZATION',
              children: [
                {
                  to: AdministrationScreens.COMPANY_LIST.PATH,
                  activePath: AdministrationScreens.COMPANY_LIST.PATH,
                  title: AdministrationScreens.COMPANY_LIST.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type !== 'ORGANIZATION',
                },
                {
                  to: AdministrationScreens.DEPARTMENT_LIST.PATH,
                  activePath: AdministrationScreens.DEPARTMENT_LIST.PATH,
                  title: AdministrationScreens.DEPARTMENT_LIST.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type !== 'ORGANIZATION',
                },
                {
                  to: AdministrationScreens.ORGANIZATION.PATH,
                  activePath: AdministrationScreens.ORGANIZATION.PATH,
                  title: AdministrationScreens.ORGANIZATION.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type !== 'SUPERADMIN',
                },
                {
                  to: AdministrationScreens.ROLE.PATH,
                  activePath: AdministrationScreens.ROLE.PATH,
                  title: AdministrationScreens.ROLE.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'USER',
                },
                {
                  to: AdministrationScreens.USER.PATH,
                  activePath: AdministrationScreens.USER.PATH,
                  title: AdministrationScreens.USER.TITLE_ALT,
                  hasBullet: true,
                  hidden: user?.role?.user_type === 'USER',
                },
              ],
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

// old sidebar menu

// export const useMenus = (user?: IUser): Menu[] => {
//   return useMemo(
//     () =>
//       [
//         {
//           children: [
//             {
//               to: DashboardScreens.DASHBOARD.PATH,
//               icon: '/media/icons/IconDashboard.svg',
//               title: DashboardScreens.DASHBOARD.TITLE,
//               hidden: user?.role?.user_type === 'SUPERADMIN',
//             },
//             {
//               to: TaskManagementScreens.TASK_MANAGEMENT.PATH,
//               icon: '/media/icons/IconTaskManagement.svg',
//               title: TaskManagementScreens.TASK_MANAGEMENT.TITLE,
//               hidden: user?.role?.user_type === 'SUPERADMIN',
//               children: [
//                 {
//                   to: TaskManagementScreens.TASK_LIST.PATH,
//                   activePath: TaskManagementScreens.TASK_LIST.PATH,
//                   title: TaskManagementScreens.TASK_LIST.TITLE,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type === 'SUPERADMIN',
//                 },
//               ],
//             },
//             {
//               to: FormManagementScreens.FORM_MANAGEMENT.PATH,
//               icon: '/media/icons/IconFormManagement.svg',
//               title: FormManagementScreens.FORM_MANAGEMENT.TITLE,
//               hidden: user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
//               children: [
//                 {
//                   to: FormManagementScreens.DYNAMIC_FORM.PATH,
//                   activePath: FormManagementScreens.DYNAMIC_FORM.PATH,
//                   title: FormManagementScreens.DYNAMIC_FORM.TITLE,
//                   hasBullet: true,
//                   hidden:
//                     user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
//                 },
//               ],
//             },
//             {
//               to: ReportScreens.REPORT_LIST.PATH,
//               icon: '/media/icons/IconReport.svg',
//               title: ReportScreens.REPORT.TITLE,
//               hidden: user?.role?.user_type === 'SUPERADMIN',
//             },
//             {
//               to: AgentManagementScreens.AGENT_MANAGEMENT.PATH,
//               icon: '/media/icons/IconAgentManagement.svg',
//               title: AgentManagementScreens.AGENT_MANAGEMENT.TITLE,
//               hidden: user?.role?.user_type === 'SUPERADMIN',
//               children: [
//                 {
//                   to: AgentManagementScreens.AGENT_LIST.PATH,
//                   activePath: AgentManagementScreens.AGENT_LIST.PATH,
//                   title: AgentManagementScreens.AGENT_LIST.TITLE,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type === 'SUPERADMIN',
//                 },
//               ],
//             },
//             {
//               to: MasterScreens.MASTER.PATH,
//               icon: '/media/icons/IconMaster.svg',
//               title: MasterScreens.MASTER.TITLE,
//               hidden: user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
//               children: [
//                 {
//                   to: MasterScreens.CATEGORY_MANAGEMENT.PATH,
//                   activePath: MasterScreens.CATEGORY_MANAGEMENT.PATH,
//                   title: MasterScreens.CATEGORY_MANAGEMENT.TITLE,
//                   hasBullet: true,
//                   hidden:
//                     user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
//                 },
//                 {
//                   to: MasterScreens.SERVICE_MANAGEMENT.PATH,
//                   activePath: MasterScreens.SERVICE_MANAGEMENT.PATH,
//                   title: MasterScreens.SERVICE_MANAGEMENT.TITLE,
//                   hasBullet: true,
//                   hidden:
//                     user?.role?.user_type === 'SUPERADMIN' || user?.role?.user_type === 'USER',
//                 },
//               ],
//             },
//             {
//               to: AdministrationScreens.ADMINISTRATION.PATH,
//               icon: '/media/icons/IconAdministration.svg',
//               title: AdministrationScreens.ADMINISTRATION.TITLE,
//               hidden: user?.role?.user_type === 'USER',
//               children: [
//                 {
//                   to: AdministrationScreens.COMPANY_LIST.PATH,
//                   activePath: AdministrationScreens.COMPANY_LIST.PATH,
//                   title: AdministrationScreens.COMPANY_LIST.TITLE_ALT,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type !== 'ORGANIZATION',
//                 },
//                 {
//                   to: AdministrationScreens.DEPARTMENT_LIST.PATH,
//                   activePath: AdministrationScreens.DEPARTMENT_LIST.PATH,
//                   title: AdministrationScreens.DEPARTMENT_LIST.TITLE_ALT,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type !== 'ORGANIZATION',
//                 },
//                 {
//                   to: AdministrationScreens.ORGANIZATION.PATH,
//                   activePath: AdministrationScreens.ORGANIZATION.PATH,
//                   title: AdministrationScreens.ORGANIZATION.TITLE_ALT,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type !== 'SUPERADMIN',
//                 },
//                 {
//                   to: AdministrationScreens.ROLE.PATH,
//                   activePath: AdministrationScreens.ROLE.PATH,
//                   title: AdministrationScreens.ROLE.TITLE_ALT,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type === 'USER',
//                 },
//                 {
//                   to: AdministrationScreens.USER.PATH,
//                   activePath: AdministrationScreens.USER.PATH,
//                   title: AdministrationScreens.USER.TITLE_ALT,
//                   hasBullet: true,
//                   hidden: user?.role?.user_type === 'USER',
//                 },
//               ],
//             },
//           ],
//         },
//       ] as Menu[],
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [user]
//   )
// }
