import {ScreenProp} from 'src/app/models'
import {CategoryManagementScreen} from './page/category-management/Screen'
import {ServiceManagementScreen} from './page/service-management/Screen'

const MasterScreens: Record<string, ScreenProp> = {
  MASTER: {
    PATH: '/master',
    TITLE: 'Master',
    TITLE_ALT: 'Master',
  },

  ...CategoryManagementScreen,
  ...ServiceManagementScreen,
}

export default MasterScreens
